import { SpinachApp, useZoomServiceSetup } from '@spinach-clients/shared';

import './App.css';

/**
 * Used to initialize any zoom-specific dependencies that may arise in the future
 */
function SpinachZoomApp() {
    // TODO: refactor this or SpinachApp such that we can use recoil for native use-cases
    const [zoomService] = useZoomServiceSetup();

    if (!zoomService) {
        return <></>;
    }

    return <SpinachApp />;
}

export default SpinachZoomApp;
