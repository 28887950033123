import { useEffect, useState } from 'react';

import { ClientLogger } from '../utils';
import { RunningContext, ZoomService } from '../zoom';

export function useZoomServiceSetup(): [ZoomService | null] {
    const [zoomService, setZoomService] = useState<ZoomService | null>(null);

    useEffect(() => {
        async function configureZoomSDK() {
            try {
                const service = new ZoomService(setZoomService);
                await service.init();
            } catch (error) {
                ClientLogger.error('failed to init zoom service in zoom app');
            }
        }

        configureZoomSDK();
    }, []);

    return [zoomService];
}

export function useZoomMeetingContext(zoomService: ZoomService | null) {
    useEffect(() => {
        async function fetchInfo() {
            if (!zoomService) {
                return;
            }

            try {
                await Promise.all([
                    zoomService.fetchUserContext(),
                    zoomService.fetchMeetingParticipants(),
                    zoomService.fetchMeetingUUID(),
                ]);
            } catch (error) {
                ClientLogger.error(`Error calling zoom APIs: ${(error as Error).message}`);
                return;
            }
        }

        if (zoomService && zoomService.runningContext === RunningContext.InMeeting) {
            fetchInfo();
        }
    }, [zoomService]);
}
