import zoomSdk, { RunningContextResponse } from '@zoom/appssdk';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';

import { ZoomParticipantRole } from '@spinach-shared/types';

import { getSpinachAPI } from '../apis';
import { ClientLogger, Globals } from '../utils';
import { URLUtil } from '../utils/URLUtil';
import {
    BaseZoomParticipant,
    GetMeetingUUIDResponse,
    RunningContext,
    ZoomAPI,
    ZoomMeetingContext,
    ZoomParticipantsResponse,
    ZoomResponse,
    ZoomUser,
} from './types';

type SetZoomService = Dispatch<SetStateAction<ZoomService | null>>;

export class ZoomService {
    unsupportedApis: string[];
    runningContext: RunningContextResponse['context'];
    meetingContext?: ZoomMeetingContext;
    user?: ZoomUser;
    participants: BaseZoomParticipant[];
    _setZoomService: SetZoomService;

    constructor(setZoomService: SetZoomService) {
        this.unsupportedApis = [];
        this.participants = [];
        this.runningContext = RunningContext.InMainClient;
        this._setZoomService = setZoomService;
    }

    public setRunningContext(context: RunningContext) {
        this.runningContext = context;
        this.setZoomService(this);
    }

    public setUnsupportedApis(unsupportedApis: string[]) {
        this.unsupportedApis = unsupportedApis;
        this.setZoomService(this);
    }

    public async init(): Promise<void> {
        const config = await zoomSdk.config({
            capabilities: Object.values(ZoomAPI),
        });

        this.runningContext = config.runningContext;
        this.unsupportedApis = config.unsupportedApis;

        zoomSdk.onAuthorized(async (event) => {
            try {
                const response = await getSpinachAPI<{ success: boolean }>('/zoomapp/auth', {
                    params: {
                        code: encodeURIComponent(event.code),
                        code_verifier: encodeURIComponent(Globals.zoomInAppInstallOAuthVerifier),
                    },
                });

                if (response?.success) {
                    window.location.reload();
                } else {
                    // TODO: how do we want to handle this
                }
            } catch (error) {
                ClientLogger.error('failed to successfully in-app auth in zoom', {
                    error: (error as AxiosError).response,
                });
            }
        });

        this.setZoomService(this);
    }

    public async fetchUserContext(): Promise<ZoomResponse<ZoomUser>> {
        const user = await this.callZoomAPI<ZoomUser>(ZoomAPI.GetUserContext);

        if (user !== undefined && !this.unsupportedApis.includes(ZoomAPI.OnParticipantChange)) {
            user.role = ZoomParticipantRole.MeetingOwner;
        }

        this.user = user;
        this.setZoomService(this);
        return user;
    }

    public async fetchMeetingContext(): Promise<ZoomResponse<ZoomMeetingContext>> {
        const meetingContext = await this.callZoomAPI<ZoomMeetingContext>(ZoomAPI.GetMeetingContext);
        this.meetingContext = meetingContext;
        this.setZoomService(this);
        return this.meetingContext;
    }

    public async fetchMeetingParticipants(): Promise<ZoomResponse<BaseZoomParticipant[]>> {
        const meetingParticipants = await this.callZoomAPI<ZoomParticipantsResponse>(ZoomAPI.GetMeetingParticipants);
        this.participants = meetingParticipants?.participants ?? [];
        this.setZoomService(this);
        return this.participants;
    }

    public async fetchMeetingUUID(): Promise<ZoomResponse<ZoomMeetingContext>> {
        const response = await this.callZoomAPI<GetMeetingUUIDResponse>(ZoomAPI.GetMeetingUUID);

        if (response?.meetingUUID) {
            this.meetingContext = {
                meetingID: response.meetingUUID,
                meetingTopic: 'Stand up',
            };
        }

        this.setZoomService(this);

        return this.meetingContext;
    }

    private setZoomService(service: ZoomService): void {
        this._setZoomService(service);
    }

    private async callZoomAPI<T>(api: ZoomAPI): Promise<ZoomResponse<T>> {
        let response: T | undefined = undefined;

        if (!this.unsupportedApis.includes(api)) {
            response = (await zoomSdk.callZoomApi(api)) as T;
        }

        return response;
    }

    public openUrl(outUrl: string) {
        URLUtil.openURL(outUrl);
    }
}
